<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
        <el-form-item label="时间">
          <el-date-picker
            v-model="times"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>
      <!-- 表格区域 -->
      <el-table :data="couponList" stripe style="width: 100%" border>
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="id" label="ID" width="55" align="center"></el-table-column>
        <el-table-column prop="name" label="用户名" width="150" align="center">
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="150" align="center">
        </el-table-column>
        <el-table-column prop="mold" label="来源渠道" width="150" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.mold == 'japanese'">恰学日语</div>
            <div v-else-if="scope.row.mold == 'french'">恰学法语</div>
            <div v-else-if="scope.row.mold == 'korean'">恰学韩语</div>
            <div v-else>恰学课堂</div>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="留言" align="center">
        </el-table-column>
        <!-- <el-table-column label="阅读状态" align="center">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.is_read == 1" >
              已读
            </el-tag>
            <el-tag type="danger" v-else @click="leaveSee(scope.row.id, 1)"
              >未读
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="rbac_name" label="阅读者" width="80" align="center">
        </el-table-column>
        <el-table-column label="阅读时间" width="150" align="center">
          <template slot-scope="scope">
            {{$moment(scope.row.read_time * 1000).format("YYYY-MM-DD HH:mm:ss")}}
          </template>
        </el-table-column> -->
        <el-table-column label="创建时间" width="150" align="center">
          <template slot-scope="scope">
            {{$moment(scope.row.create_time * 1000).format("YYYY-MM-DD HH:mm:ss")}}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" v-slot="scope" width="300">
          <el-button type="danger" icon="el-icon-delete" circle @click="removeOrder(scope.row.id)"></el-button>
           <el-button
              type="success"
              icon="el-icon-info"
              @click="toViewDetails(scope.row)"
              circle
            ></el-button>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryParams.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>
    </el-card>
    <!-- 详情弹框 -->
    <el-dialog title="详情" :visible.sync="detailsDialog" width="50%">
      <el-form :model="detailsData">
        <el-form-item label="ID：" label-width="100px">
          {{ detailsData.id }}
        </el-form-item>

        <el-form-item label="名称" label-width="100px">
          {{ detailsData.name }}
        </el-form-item>
        <el-form-item label="渠道：" label-width="100px">
          {{ detailsData.mold }}
        </el-form-item>
        <el-form-item label="联系方式：" label-width="100px">
          {{ detailsData.phone }}
        </el-form-item>
        <!-- <el-form-item label="阅读者：" label-width="100px">
          {{ detailsData.rbac_name }}
        </el-form-item>
        <el-form-item label="阅读状态：" label-width="100px">
          <el-tag type="success" v-if="detailsData.is_read == 1" >
            已读
          </el-tag>
          <el-tag type="danger" v-else
            >未读
          </el-tag>
        </el-form-item> -->
        <el-form-item label="留言内容：" label-width="100px">
          <el-input type="textarea" :autosize="{minRows: 8}" :value="detailsData.remark" ></el-input>
        </el-form-item>
        <el-form-item label="创建时间：" label-width="100px">
          {{
            $moment(detailsData.create_time * 1000).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          }}
        </el-form-item>
        <el-form-item label="更新时间：" label-width="100px">
          {{
            $moment(detailsData.update_time * 1000).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          }}
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getUserLeaveList,removeUserLeave,userLeaveSee} from "@/api/userLeave";
export default {
  data() {
    return {
      couponList: [],
      queryParams: {
        page: 1,
        limit: 10,
        code: "",
        start_time: 0,
        end_time: 0,
        goods_id:null
      },
      total: 0,
      times: null,
      detailsData: {}, //详情数据
      detailsDialog: false //详情弹框
    };
  },

  methods: {
    // 查看详情
    toViewDetails(row) {
      this.detailsData = row;
      console.log(row)
      this.detailsDialog = true;
    },
    handleSizeChange(size) {
      this.queryParams.limit = size;
      this.getCouponList();
    },
    handleCurrentChange(page) {
      this.queryParams.page = page;
      this.getCouponList();
    },
    // 获取全部
    getCouponList() {
      getUserLeaveList(this.queryParams).then(res => {

        this.couponList = res.data.data;
        this.total = res.data.total;
      });
    },
    // 删除
    async removeOrder(i) {
      const result = await this.$confirm(
        "请问是否要删除该用户留言?",
        "删除提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).catch(err => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");

      removeUserLeave(i).then(res => {
        if (res.code !== 1) {

          this.$message.error("删除失败");
        }
        this.$message.success("删除成功");
        this.getCouponList();
      });
    },
    // 用户读取状态
    leaveSee(id) {
      userLeaveSee(id).then(res => {
          this.$message.success("设置成功");
          this.getCouponList();
        });
    },
    // 查询
    onSubmit() {
      this.queryParams.page=1
      if (this.times) {
        this.queryParams.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryParams.end_time = new Date(this.times[1]).getTime() / 1000;
      }
      this.getCouponList();
    },
  },
  created() {
    this.getCouponList();
  }
}
</script>

<style scoped>
.el-row {
  margin-bottom: 30px;
  font-size: 24px;
}
.el-tag {
  cursor: pointer;
}
.pagination {
  display: flex;
  justify-content: space-between;
}
::v-deep .el-pager li.active{
  color: #FF6688;
}
</style>
