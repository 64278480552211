import request from "@/utils/request";
// import { getToken } from "@/utils/auth";

export function getUserLeaveList(query) {
  return request({
    url: "/admin/base/userLeaveList",
    method: "get",
    params: query
  });
}
export function removeUserLeave(data) {
  return request({
    url: "/admin/base/userLeaveDel",
    method: "delete",
    data: {
      id: data
    }
  });
}
export function userLeaveSee(data) {
  return request({
    url: "/admin/base/userLeaveSee",
    method: "get",
    params: {
      id: data
    }
  });
}

